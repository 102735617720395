import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import stylesTheHotel from "./thehotel.module.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import user1 from "../../Assets/images/user-1.png";
import user2 from "../../Assets/images/user-2.png";
import user3 from "../../Assets/images/user-3.png";
import user4 from "../../Assets/images/user-4.png";
import feedback from "../../Assets/images/feedback.png";
import photo from "../../Assets/photos/photo-recepcao.png";
import icons from "../../styles/icons";

function TheHotel() {
  const [isLoaded, setIsLoaded] = useState({});

  const handleImageLoad = (index) => {
    setIsLoaded((prevState) => ({ ...prevState, [index]: true }));
  };

  return (
    <>
      <Header />

      <figure className={stylesTheHotel["wrap-figure"]}>
        <div className={stylesTheHotel["wrap-txt-figure"]}>
          <p>o hotel</p>
        </div>
      </figure>

      <section className={stylesTheHotel["section-1"]}>
        <div className={stylesTheHotel["background-section-1"]}>
          <div className={stylesTheHotel["txt-section-1"]}>
            <p className="font-m">
              O planalto palace hotel oferece o melhor atendimento e hospedagem
            </p>
            <span className="font-mm">
              Além de uma excelente localização, oferecemos também serviços
              diferenciados aos nossos hóspedes como estacionamento e
              restaurante.
            </span>
            <span className="font-mm">
              Somos referência em hotelaria na região oeste do Pará
            </span>
          </div>
        </div>
      </section>

      <section className={stylesTheHotel["section-2"]}>
        <div className={stylesTheHotel["background-section-2"]}>
          <div className={stylesTheHotel["txt-section-2"]}>
            <p className="font-m">resumo</p>
            <span>Acomodação 20 apartamentos e suítes</span>
            <span>
              Refeições somos o único hotel na cidade que oferece um restaurante
            </span>
            <span>
              Destaque um lindo entardecer, proporcionando um exuberante por do
              sol, você pode admirar essa maravilha pela recepção do hotel todas
              as tardes as 18:30
            </span>
            <span>Eventos 1 sala de reuniões com capacidade de 35 pessoas</span>
            <span>
              Localização estamos a 1km do centro da cidade de ruropolis
            </span>
            <span>Explore o planalto palace</span>
            <span>
              Enfrente a rodovia transamazônica, temos inúmeras historias desde
              2018.
            </span>
            <span>
              Sua estadia conosco promete ser uma experiência inesquecível!
            </span>
          </div>
        </div>
      </section>

      <section className={stylesTheHotel["section-3"]}>
        <div className={stylesTheHotel["txt-section-3"]}>
          <p className="font-g">diferente. melhor. especial</p>
          <span className="font-m">Veja o que nossos hóspedes falam</span>
        </div>
      </section>

      <section className={stylesTheHotel["section-4"]}>
        <div class={stylesTheHotel["feedback-container"]}>
          <div class={stylesTheHotel["feedback-card"]}>
            <img
              src={user1}
              alt="Customer Photo"
              class={stylesTheHotel["customer-photo"]}
            />
            <div class={stylesTheHotel["customer-info"]}>
              <h3 class={stylesTheHotel["customer-name"]}>Antony Renan</h3>
              <div class={stylesTheHotel["stars"]}>★★★★★</div>
              <p class={stylesTheHotel["comment"]}>
                "O Planalto Palace Hotel superou minhas expectativas! Ótima
                localização, atendimento impecável e quartos confortáveis.
                Voltarei com certeza!"
              </p>
            </div>
          </div>

          <div class={stylesTheHotel["feedback-card"]}>
            <img
              src={user2}
              alt="Customer Photo"
              class={stylesTheHotel["customer-photo"]}
            />
            <div class={stylesTheHotel["customer-info"]}>
              <h3 class={stylesTheHotel["customer-name"]}>Murilo Moschen</h3>
              <div class={stylesTheHotel["stars"]}>★★★★★</div>
              <p class={stylesTheHotel["comment"]}>
                "Fiquei encantado com a limpeza e o ambiente acolhedor do hotel.
                O café da manhã é variado e delicioso. Recomendo muito para quem
                visita a cidade!"
              </p>
            </div>
          </div>

          <div class={stylesTheHotel["feedback-card"]}>
            <img
              src={user3}
              alt="Customer Photo"
              class={stylesTheHotel["customer-photo"]}
            />
            <div class={stylesTheHotel["customer-info"]}>
              <h3 class={stylesTheHotel["customer-name"]}>Rafael Alfaia</h3>
              <div class={stylesTheHotel["stars"]}>★★★★★</div>
              <p class={stylesTheHotel["comment"]}>
                "Perfeito para viagens a trabalho. Wi-fi rápido, áreas comuns
                bem equipadas e a gerente é sempre muito atenciosa. O melhor
                hotel da região!"
              </p>
            </div>
          </div>
          <div class={stylesTheHotel["feedback-card"]}>
            <img
              src={user4}
              alt="Customer Photo"
              class={stylesTheHotel["customer-photo"]}
            />
            <div class={stylesTheHotel["customer-info"]}>
              <h3 class={stylesTheHotel["customer-name"]}>Artur Araujo</h3>
              <div class={stylesTheHotel["stars"]}>★★★★★</div>
              <p class={stylesTheHotel["comment"]}>
                "Atendentes educados e dispostos a atender o cliente da melhor
                maneira. Ambiente especial, quartos limpos, banheiro grande e
                bem arrumado, camas ótimas. Hotel realmente diferenciado."
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={`${stylesTheHotel["section-5"]} container`}>
        <div className={stylesTheHotel["wrap-content-section-5"]}>
          <div className={stylesTheHotel["wrap-txt-section-5"]}>
            <p className="font-m txt-end">Sua casa longe de casa</p>
            <p className="font-xs">
              Nossa missão é transformar sua vinda em Rurópolis uma experiencia
              inesquecível, trabalhamos com muito amor e dedicação para lhe
              oferecer um ambiente que supere suas expectativas em termos de
              conforto, qualidade e conveniência.
            </p>
            <p className="font-xs">
              Para quem viaja até Ruropolis a trabalho, lazer ou estudo, é ótimo
              saber que uma segunda casa, com a melhor acomodação e atendimento,
              esta de portas abertas para os momentos de verdadeiro descanso.
            </p>
            <p className="font-s txt-end line-button-right">saiba mais</p>
          </div>
          <div className={stylesTheHotel["wrap-img-section-5"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesTheHotel["img-section-5"]} ${
                  stylesTheHotel["img-start"]
                } ${isLoaded[3] ? stylesTheHotel["fade-in"] : ""}`}
                src={photo}
                alt=""
                onLoad={() => handleImageLoad(3)}
              />
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className={stylesTheHotel["section-6"]}>
        <div className={stylesTheHotel["txt-section-6"]}>
          <p className="font-m">Por que Você deve se hospedar aqui?</p>
          <span className="font-sm">
            3 simples e poderosos motivos para você reservar um quarto ainda
            hoje!
          </span>
        </div>
      </section>

      <section className={stylesTheHotel["section-7"]}>
        <div className={stylesTheHotel["wrap-content-section-7"]}>
        <div className={stylesTheHotel["wrap-txt-section-7"]}>
          <div className={stylesTheHotel["reason"]}>
            <span className={stylesTheHotel["number-reason"]}>1</span>
            <p className="font-m">Ambiente Acolhedor e Aconchegante</p>
            <p className="font-sm">
              O Nosso hotel foi projetado desde o inicio, pensando em você se
              sentir em casa. Com um ambiente extremamente acolhedor e
              aconchegante, estamos te esperando!
            </p>
          </div>
          <div className={stylesTheHotel["reason"]}>
            <span className={stylesTheHotel["number-reason"]}>2</span>
            <p className="font-m">Localização Privilegiada</p>
            <p className="font-sm">
              Estamos localizados no coração de Rurópolis, isso te deixa um pouco
              mais perto de onde Você precisa estar.
            </p>
          </div>
          <div className={stylesTheHotel["reason"]}>
            <span className={stylesTheHotel["number-reason"]}>3</span>
            <p className="font-m">Gastronomia Excepcional</p>
            <p className="font-sm">
            Desfrute de pratos requintados em nosso restaurante, que combina ingredientes frescos e sabores.
            </p>
          </div>
        </div>

        <div className={stylesTheHotel["wrap-img-section-7"]}>
          <img src={feedback} alt="" />
        </div>
        </div>
      </section>

      <section className={stylesTheHotel["section-9"]}>
        <div className={stylesTheHotel["wrap-contents-section-9"]}>
          <div className={stylesTheHotel["wrap-content-1-section-9"]}>
            <img className="icons-g" src={icons.question} alt="" />
            <span>Perguntas Frequentes</span>
            <p className="font-mm">
              Consulte nossas perguntas frequentes antes da sua estadia no
              Copacabana Palace.
            </p>
            <p className="font-s txt-end line-button-left">Saiba mais</p>
          </div>
          <div className={stylesTheHotel["wrap-content-2-section-9"]}>
            <img className="icons-g" src={icons.phone} alt="" />
            <span>Fale Conosco</span>
            <p className="font-mm">
              Entre em contato e comece a planejar a sua estadia em Rurópolis no
              Planalto Palace Hotel.
            </p>
            <p>+55 93 98421-3759</p>
            <p className="font-s txt-end line-button-left">Entrar em contato</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default TheHotel;
