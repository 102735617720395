import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import stylesGallery from "./gallery.module.css"
import photos from "../../Assets/gallery/photos/photos";

function gallery(){
    return(
    <>
    <Header />
    
    <section className={stylesGallery["section-1"]}>
        <div className={stylesGallery["wrap-txt-section-1"]}>
            <p className="font-g">Uma curta descrição do Nosso Mundo</p>
            <p className="font-sm">Pelas fotos para uma amostra do que te espera no <b>Planalto Palace Hotel</b></p>
        </div>
    </section>

    <section className={stylesGallery["section-2"]}>

    <div className={stylesGallery["row"]}>
        <div className={stylesGallery["column"]}>
            <img src={photos.photo12} />
            <img src={photos.photo4} />
            <img src={photos.photo19} />
            <img src={photos.photo5} />
            <img src={photos.photo6} />
            <img src={photos.photo8} />
            <img src={photos.photo15} />
            <img src={photos.photo25} />
            <img src={photos.photo23} />
        </div>
        <div className={stylesGallery["column"]}>
            <img src={photos.photo9} />
            <img src={photos.photo10} />
            <img src={photos.photo11} />
            <img src={photos.photo27} />
            <img src={photos.photo13} />
            <img src={photos.photo14} />
            <img src={photos.photo1} />
            
            <img src={photos.photo7} />
            <img src={photos.photo27} />
        </div>
        <div className={stylesGallery["column"]}>
            <img src={photos.photo16} />
            <img src={photos.photo17} />
            <img src={photos.photo18} />
            <img src={photos.photo20} />
            <img src={photos.photo21} />
            <img src={photos.photo22} />
            <img src={photos.photo16} />   
            <img src={photos.photo26} />   
            <img src={photos.photo24} />   
        </div>
    </div>
    </section>
    
    <Footer />
    </>
    )
};

export default gallery;