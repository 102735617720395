import React, { useState } from "react";
import stylesHome from "./home.module.css";
import LazyLoad from "react-lazyload";
import "../../styles/global.css";
import Footer from "../../components/footer/footer.jsx";
import quarto from "../../Assets/photos/foto-apartamentos.jpg";
import suite from "../../Assets/photos/foto-suite.jpg";
import example from "../../Assets/photos/fotobase.png";
import fxexample from "../../Assets/photos/ftexmpl.jpg";
import chavenova from "../../Assets/images/Chave nova.png";
import Header from "../../components/header/header.jsx";

function Home() {
  const [isLoaded, setIsLoaded] = useState({});

  const handleImageLoad = (index) => {
    setIsLoaded((prevState) => ({ ...prevState, [index]: true }));
  };

  return (
    <>
      <Header />

      <figure className={stylesHome["wrap-figure"]}>
        <div className={stylesHome["wrap-txt-figure"]}>
          <p>acomodações</p>
          <a>reserve agora</a>
        </div>
      </figure>

      <section className={stylesHome["section-1"]}>
        <div className={stylesHome["wrap-section-1"]}>
          <LazyLoad height={200} offset={100} once>
            <img
              className={`${stylesHome["section-1-img"]} ${["img-start"]} ${
                isLoaded[1] ? stylesHome["fade-in"] : ""
              }`}
              src={chavenova}
              alt="chaves"
              onLoad={() => handleImageLoad(1)}
            />
          </LazyLoad>
          <p id={stylesHome["section-1-txt-sm"]}>
            Sinta- <br /> se em <br /> casa
          </p>
        </div>
        <div className={stylesHome["txt-1-section-1"]}>
          <p id={stylesHome["txt-2-section-1"]}>
            Ao acordar abra a janela e sinta a brisa da Praia de Copacabana.
            Hospede-se em um dos apartamentos cheios de história que tiveram a
            honra de receber nomes ilustres como Marlene Dietrich, Orson Welles
            e Gisele Bündchen. Sente-se e relaxe com uma taça de champanhe
            refrescante em um dos nossos sofisticados ambientes, decorados com
            móveis de madeira de mogno e freijó. Os banheiros ganham destaque
            com revestimento de mármore brasileiro rosa e branco. Para desfrutar
            de uma pitada extra de magia, nossas sete suítes Cobertura são
            verdadeiros oásis com tapetes orientais, delicados tecidos
            franceses, seleção de antiguidades e terraços.
          </p>
        </div>
      </section>

      <section className={stylesHome["section-2"]}>
        <div className={stylesHome["wrap-section-2"]}>
          <p className="font-g">apartamentos e suítes</p>
          <p id={stylesHome["section-2-txt-2"]}>
            Decidindo onde ficar no Rio de Janeiro? Nossos apartamentos e suítes
            são os mais exclusivos da Cidade Maravilhosa.
          </p>
          <LazyLoad height={200} offset={100} once>
            <img
              className={`${stylesHome["wrap-photo-section-2"]} ${stylesHome["img-start"]}  ${
                isLoaded[2] ? stylesHome["fade-in"] : ""
              }`}
              src={quarto}
              alt="foto quarto"
              onLoad={() => handleImageLoad(2)}
            />
          </LazyLoad>
          <div
            className={stylesHome["border-bottom-wrap-photo-section-2"]}
          ></div>
          <p className="font-m">apartamentos</p>
          <div className={stylesHome["wrap-desc"]}>
            <p className="font-xs txt-bola"> Cama king size</p>
            <p className="font-xs txt-bola"> Banheiro espaçoso</p>
            <p className="font-xs"> Tamanho: a partir de 30 m²</p>
          </div>
          <p className="btn-border">detalhes completos</p>
        </div>
      </section>

      <section className={stylesHome["section-3"]}>
        <div className={stylesHome["wrap-content-section-3"]}>
          <div className={stylesHome["wrap-img-section-3"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesHome["img-content-section-3"]} ${stylesHome["img-start"]} ${
                  isLoaded[3] ? stylesHome["fade-in"] : ""
                }`}
                src={suite}
                alt="suite"
                onLoad={() => handleImageLoad(3)}
              />
            </LazyLoad>
            <p className="font-m">Suítes</p>
          </div>
          <div className={stylesHome["wrap-desc"]}>
            <p className="font-xs txt-bola">
              Cama king size ou camas de solteiro
            </p>
            <p className="font-xs txt-bola">Sala de estar espaçosa</p>
            <p className="font-xs">Tamanho: a partir de 30 m²</p>
          </div>
          <p className="btn-border">detalhes completos</p>
        </div>

        <div className={stylesHome["wrap-content-section-3"]}>
          <div className={stylesHome["wrap-img-section-3"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesHome["img-content-section-3"]} ${stylesHome["img-start"]} ${
                  isLoaded[3] ? stylesHome["fade-in"] : ""
                }`}
                src={suite}
                alt="suite"
                onLoad={() => handleImageLoad(3)}
              />
            </LazyLoad>
            <p className="font-m">Suítes</p>
          </div>
          <div className={stylesHome["wrap-desc"]}>
            <p className="font-xs txt-bola">
              Cama king size ou camas de solteiro
            </p>
            <p className="font-xs txt-bola">Sala de estar espaçosa</p>
            <p className="font-xs">Tamanho: a partir de 30 m²</p>
          </div>
          <p className="btn-border">detalhes completos</p>
        </div>
      </section>

      <section className={stylesHome["section-4"]}>
        <div className={stylesHome["wrap-content-section-4"]}>
          <div className={stylesHome["wrap-txt-section-4"]}>
            <p id={stylesHome["txt-1-section-4"]} className="font-m">
              Elegância atemporal em um paraíso privado
            </p>
            <p id={stylesHome["txt-2-section-4"]} className="font-xs">
              Viva uma experiência exclusiva em família ou com os amigos no
              andar mais cobiçado do Copa. Todas as suítes do sexto andar e a
              Black Pool reservadas só para você.
            </p>
            <p className="font-s line-button-left">
              Contacte-nos para reservar
            </p>
          </div>
          <div className={stylesHome["img-section-4"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesHome["img-content-section-3"]} ${stylesHome["img-start"]} ${
                  isLoaded[4] ? stylesHome["fade-in"] : ""
                }`}
                src={example}
                alt="suite"
                onLoad={() => handleImageLoad(4)}
              />
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className={stylesHome["section-5"]}>
        <div className={stylesHome["wrap-img-section-5"]}>
          <LazyLoad height={200} offset={100} once>
            <img
              className={`${stylesHome["img-section-5"]} ${stylesHome["img-start"]} ${
                isLoaded[5] ? stylesHome["fade-in"] : ""
              }`}
              src={fxexample}
              alt="comemoração"
              onLoad={() => handleImageLoad(5)}
            />
          </LazyLoad>
        </div>
        <div className={stylesHome["wrap-all-txt"]}>
          <div className={`${stylesHome["txt-1-section-5"]} font-s`}>
            <p>comemore</p>
          </div>
          <div className={stylesHome["wrap-txt-section-5"]}>
            <div className="font-m">
              <p>venha comemorar</p>
            </div>
            <div className="font-xs">
              <p>
                Seja para um aniversário, bodas ou lua de mel, este pacote
                comemorativo inclui adoráveis mimos que tornarão sua estadia
                verdadeiramente inesquecível.
              </p>
            </div>
            <div className={stylesHome["list-section-5"]}>
              <ul>
                <li>
                  <p className="txt-forma-before font-xs">Acomodação de luxo</p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Gift de boas-vindas, incluindo espumante, flores e trufas de
                    chocolate
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Café da manhã brasileiro diário para duas pessoas no
                    Restaurante Pérgula
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Check-out tardio até as 16h
                  </p>
                </li>
              </ul>
            </div>
            <div className={`${stylesHome["txt-4-section-5"]} font-s`}>
              <p>verificar disponibilidade</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Home;
