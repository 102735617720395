import React, { useState } from "react";
import stylesRestaurant from "./restaurant.module.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import photoRestaurant from "../../Assets/photos/foto-comida.png";
import photoRestaurant2 from "../../Assets/photos/foto-comida-2.png";


function Restaurant() {
  return (
    <>
      <Header />

      <figure className={stylesRestaurant["wrap-figure"]}>
        <div className={stylesRestaurant["wrap-txt-figure"]}>
          <p>Restaurante</p>
        </div>
      </figure>

      <section className={stylesRestaurant["section-1"]}>
        <div className={stylesRestaurant["wrap-txt-section-1"]}>
          <p className="font-g">
            Pólo gastronômico exclusivo do Planalto Palace Hotel
          </p>
          <p className="font-sm">
            Nossos restaurantes e Pool Bar são os ambientes perfeitos para
            celebrar a vida, desde coquetel com vista para a piscina até a mesa
            do chef no Cipriani. Comece seu roteiro com refrescantes drinks à
            beira da piscina, em nosso Pool Bar. Deguste a autêntica culinária
            internacional com influência de produtos brasileiros no Pérgula.
            Delicie-se com a riqueza da gastronomia pan-asiática no Mee, com
            Estrela Michelin. Ou saboreie a sofisticada e autêntica cozinha
            italiana no Ristorante Hotel Cipriani, também com estrela Michelin.
          </p>
        </div>
      </section>

      <section className={stylesRestaurant["section-2"]}>
        <div className={stylesRestaurant["wrap-content-section-2"]}>
          <div className={stylesRestaurant["wrap-txt-section-2"]}>
            <p className="font-m">RESTAURANTE</p>
            <p className="font-sm">
              Restaurante Pérgula O Pérgula reflete o clima animado do Rio de
              Janeiro. Sinta-se seguro e exclusivo em nossas confortáveis mesas
              enquanto saboreia o menu desenvolvido especialmente para você.
              Nossa carta de vinhos e drinks te aguardam para apreciar junto com
              a vista da nossa piscina ou da Praia de Copacabana.
            </p>
            <p className="font-sm">
              Café da manhã: 6h30 até 10h30 (Segunda à Sexta), 7h até 11h
              (Sábados e Domingos) Almoço: 12h até 16h (Segunda à sexta)
              Feijoada: 13h até 16h (aos Sábados) Brunch: 13h até 16h (aos
              Domingos) Jantar: 18h até 00h (cozinha fecha às 23h todos os dias)
            </p>
            <p className="font-s txt-end line-button-right">
              reservar uma mesa
            </p>
          </div>
          <div className={stylesRestaurant["wrap-bg-section-2"]}>
            <span className={stylesRestaurant["bg-section-2"]}></span>
          </div>
          <div className={stylesRestaurant["wrap-photo"]}>
            <img
              className={stylesRestaurant["img-section-2"]}
              src={photoRestaurant}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className={stylesRestaurant["section-3"]}>
        <div className={stylesRestaurant["wrap-content-section-3"]}>
        <div className={stylesRestaurant["wrap-photo"]}>
            <img
              className={stylesRestaurant["img-section-3"]}
              src={photoRestaurant2}
              alt=""
            />
          </div>
          <div className={stylesRestaurant["wrap-txt-section-3"]}>
            <p className="font-m">RESTAURANTE</p>
            <p className="font-sm">
              Restaurante Pérgula O Pérgula reflete o clima animado do Rio de
              Janeiro. Sinta-se seguro e exclusivo em nossas confortáveis mesas
              enquanto saboreia o menu desenvolvido especialmente para você.
              Nossa carta de vinhos e drinks te aguardam para apreciar junto com
              a vista da nossa piscina ou da Praia de Copacabana.
            </p>
            <p className="font-sm">
              Café da manhã: 6h30 até 10h30 (Segunda à Sexta), 7h até 11h
              (Sábados e Domingos) Almoço: 13h até 16h (Segunda à sexta)
              Feijoada: 13h até 16h (aos Sábados) Brunch: 13h até 16h (aos
              Domingos) Jantar: 18h até 00h (cozinha fecha às 33h todos os dias)
            </p>
            <p className="font-s txt-end line-button-right">
              reservar uma mesa
            </p>
          </div>
          <div className={stylesRestaurant["wrap-bg-section-3"]}>
            <span className={stylesRestaurant["bg-section-3"]}></span>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Restaurant;
