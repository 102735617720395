import React, { useState } from "react";
import "../../styles/icons.js";
import stylesFooter from "./footer.module.css";
import icons from "../../styles/icons.js";

function Footer() {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: ""
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: ""
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let valid = true;
    const newErrors = { firstName: "", lastName: "", phoneNumber: "", email: "" };

    if (formValues.firstName.trim() === "") {
      newErrors.firstName = "Por favor, insira seu nome.";
      valid = false;
    }
    if (formValues.lastName.trim() === "") {
      newErrors.lastName = "Por favor, insira seu sobrenome.";
      valid = false;
    }
    if (formValues.phoneNumber.trim() === "") {
      newErrors.phoneNumber = "Por favor, insira seu número de telefone.";
      valid = false;
    }
    if (formValues.email.trim() === "") {
      newErrors.email = "Por favor, insira um endereço de email.";
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      console.log("Formulário enviado:", formValues);
    }
  };

  return (
    <footer className={stylesFooter["footer"]}>
      <div className={stylesFooter["container-footer"]}>
        <div className={stylesFooter["contents"]}>
          <div className={stylesFooter["content-1"]}>
            <div className={stylesFooter["wrap-group-1"]}>
              <p className="font-s">Fale Conosco</p>

              <div className={stylesFooter["numbers"]}>
                <div className={stylesFooter["number-1"]}>
                  <p className="font-xs">Reservas</p>
                  <div className="txt-bola-before"></div>
                  <a className="font-xs" href="tel:+552125458787">
                    +55 93 98421-3759
                  </a>
                </div>
                <div className={stylesFooter["number-2"]}>
                  <p className="font-xs">Telefone</p>
                  <div className="txt-bola-before"></div>
                  <a className="font-xs" href="tel:+552125458787">
                    +55 93 99128-8224
                  </a>
                </div>
              </div>

              <div className={stylesFooter["address"]}>
                <p className="font-xs">Endereço</p>
                <div className="txt-bola-before"></div>
                <span className="font-xs">
                  Rodovia Transamazônica, SN ° Km 01 - planalto, <br /> Rurópolis - PA
                </span>
              </div>

              <div className={stylesFooter["btn-1-footer"]}>
                <p className="btn-border">Entre em contato conosco</p>
              </div>

              <div className={stylesFooter["btn-2-footer"]}>
                <p className={`${stylesFooter["txt-2-content-1-footer"]} font-s`}>
                  Conte suas experiências com o Palace hotel
                </p>
              </div>

              <div className={stylesFooter["social-media"]}>
                <div className={stylesFooter["txt-social-media"]}>
                  <p className="font-s">Siga-nos</p>
                </div>

                <div className={stylesFooter["imgs-social-media"]}>
                  <a href="https://www.facebook.com/HOTELPLANALTOPALACE" target="blank" rel="noopener noreferrer">
                  <img className="icons-sm" src={icons.facebook} alt="Facebook" />
                  </a>
                  <a href="https://www.instagram.com/planaltopalacehotel/" target="blank" rel="noopener noreferrer">
                  <img className="icons-sm" src={icons.instagram} alt="Instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={stylesFooter["content-2"]}>
            <div className={stylesFooter["txt-1-content-2-footer"]}>
              <p id={stylesFooter["nowrap"]} className="font-s">
                Explore nosso mundo
              </p>
            </div>

            <div className={`${stylesFooter["wrap-group-txt-content-2-footer"]} font-xs`}>
              <p>Fale Conosco</p>
              <p>Ache-nos</p>
              <p>Cartão Presente</p>
              <p>Privacidade e Cookies</p>
              <p>Termos e Condições</p>
            </div>
          </div>

          <div className={stylesFooter["content-3"]}>
            <div className={stylesFooter["container-contents-footer"]}>
              <p className={`${stylesFooter["txt-1-content-3-footer"]} font-s`}>
                Registre-se para receber notícias exclusivas, inspiração e ofertas para viagens
              </p>
              <form id={stylesFooter["signupForm"]} onSubmit={handleSubmit}>
                <div className={stylesFooter["wrap-form-group"]}>
                  <div className={stylesFooter["form-group"]}>
                    <input
                      type="text"
                      id="firstName"
                      value={formValues.firstName}
                      onChange={handleInputChange}
                      placeholder=" "
                      maxLength={30}
                    />
                    <label htmlFor="firstName" className="font-xs">Nome</label>
                    {errors.firstName && (
                      <span className={`${stylesFooter["error-message"]} ${stylesFooter["error"]}`}>
                        {errors.firstName}
                      </span>
                    )}
                  </div>
                  <div className={stylesFooter["form-group"]}>
                    <input
                      type="text"
                      id="lastName"
                      value={formValues.lastName}
                      onChange={handleInputChange}
                      placeholder=" "
                      maxLength={30}
                    />
                    <label htmlFor="lastName" className="font-xs">Sobrenome</label>
                    {errors.lastName && (
                      <span className={`${stylesFooter["error-message"]} ${stylesFooter["error"]}`}>
                        {errors.lastName}
                      </span>
                    )}
                  </div>
                  <div className={stylesFooter["form-group"]}>
                    <input
                      type="number"
                      id="phoneNumber"
                      value={formValues.phoneNumber}
                      onChange={handleInputChange}
                      placeholder=" "
                    />
                    <label htmlFor="phoneNumber" className="font-xs">Número</label>
                    {errors.phoneNumber && (
                      <span className={`${stylesFooter["error-message"]} ${stylesFooter["error"]}`}>
                        {errors.phoneNumber}
                      </span>
                    )}
                  </div>
                </div>
                <div className={stylesFooter["form-group"]}>
                  <input
                    type="email"
                    id="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    placeholder=" "
                    maxLength={30}
                  />
                  <label htmlFor="email" className="font-xs">Insira um endereço de email</label>
                  {errors.email && (
                    <span className={`${stylesFooter["error-message"]} ${stylesFooter["error"]}`}>
                      {errors.email}
                    </span>
                  )}
                </div>
                <div>
                  <p className="font-xxs">
                    Ao inserir suas informações, você consentirá em receber, por e-mail, informações sobre as ofertas e atualizações do grupo Planalto Palace Hotel. Para cancelar o recebimento, use o link de cancelamento ou envie um e-mail para planaltopalacehotel12@gmail Consulte a nossa política de privacidade para obter detalhes.
                  </p>
                </div>
                <div className={stylesFooter["btn-1-form"]}>
                  <input className="btn-border" type="submit" value="Cadastrar-se" />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={stylesFooter["content-4"]}>
          <div className={`${stylesFooter["txt-copyright"]} font-xxs`}>
            <p>
              <span>Copyright Planalto Palace Hotel</span> 2024. Todos os direitos autorais e direitos de propriedade intelectual em todos os logotipos, designs, textos, imagens e outros materiais apresentados neste site são de posse do Planalto Palace Hotel ou aparecem com a devida autorização do proprietário correspondente. "Planalto Palace Hotel" é uma marca registrada. Todos os direitos reservados.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
