import Sidebar from "./components/sidebar/sidebar.jsx";
import Home from "./pages/home/home.jsx";
import Footer from "./components/footer/footer.jsx";
import Header from "./components/header/header.jsx"
import TheHotel from "./pages/the hotel/thehotel.jsx"
import Atividades from "./pages/activities/activities.jsx"
import Acomodações from "./pages/home/home.jsx"
import Galeria from "./pages/gallery/gallery.jsx"
import Eventos from "./pages/events/events.jsx"
import Ofertas from "./pages/offers/offers.jsx"
import Restaurant from "./pages/restaurant/restaurant.jsx";
import { SidebarProvider } from './context/SidebarContext';
import { BrowserRouter, Form, Link, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
    <SidebarProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Acomodações" element={<Acomodações />} />
        <Route path="/Footer" element={<Footer />} />
        <Route path="/Header" element={<Header />} />
        <Route path="/Sidebar" element={<Sidebar />} />
        <Route path="/Ohotel" element={<TheHotel />} />
        <Route path="/Atividades" element={<Atividades />} />
        <Route path="/Galeria" element={<Galeria />} />
        <Route path="/Eventos" element={<Eventos />} />
        <Route path="/Ofertas" element={<Ofertas />} />
        <Route path="/Restaurante" element={<Restaurant />} />
      </Routes>
      </SidebarProvider>
    </BrowserRouter>
  );
}

export default App;
